<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light" id="header">
    <span href="#" style="margin-right: 10px;"
      ><img
        src="@/assets/logo_transparent.png"
        alt=""
        style="margin-right: 0px;height: 50px;"
    /></span>
    <h1 class="logo mr-auto"><span>Know</span>prep</h1>
  </nav>
</template>

<script>
export default {
  name: "DemoNavbar"
};
</script>

<style scoped>
#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
#header .logo span {
  color: #d42525;
}
</style>
