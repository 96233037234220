<template>
  <div>
    <DemoNavbar></DemoNavbar>
    <div class="question-type-nav">
      <button
        :class="{ 'active-table': isViewingBrief === 0 }"
        class="mcq btn "
        @click="isViewingBrief = 0"
      >
        Brief Analysis
      </button>
      <button
        :class="{ 'active-table': isViewingBrief === 2 }"
        class="subjective-question btn "
        @click="isViewingBrief = 2"
      >
        Detailed Analysis
      </button>

      <div class="data" v-if="!isViewingBrief">
        <ResultTable
          :result-data="{
            answered,
            unanswered,
            score,
            highestScore: score
          }"
        ></ResultTable>
        <GeneralPerformanceTable
          :general-performance-data="sectionArray"
        ></GeneralPerformanceTable>
      </div>
      <div class="data" v-else>
        <div class="question-type-nav">
          <button
            :class="{ 'active-table': isViewingSubjective == 0 }"
            class="mcq btn "
            @click="isViewingSubjective = 0"
          >
            Multiple Choice Question
          </button>
          <button
            :class="{ 'active-table': isViewingSubjective == 1 }"
            class="subjective-question btn "
            @click="isViewingSubjective = 1"
          >
            One Word Questions
          </button>
        </div>
        <div class="table-container">
          <table class="table">
            <DetailedAnalysisRow
              col0="Sno"
              col1="Question"
              col2="Answer"
              col3="Difficulty"
              col4="Your Answer"
              col5="Your Marks"
              col6="Explanation"
              row-back-ground-color="#46A049"
              font-weight="bolder"
              :render="true"
              :render-answer="isViewingSubjective == 2 ? false : true"
            ></DetailedAnalysisRow>

            <DetailedAnalysisRow
              v-for="(row, index) in tableData"
              :key="index"
              :col0="index + 1"
              :col1="row.question"
              :col2="row.answer"
              :col3="row.difficulty"
              :col4="row.userAnswer != -1 ? row.userAnswer : ''"
              :col5="row.marks"
              :col6="row.explanation"
              :render-answer="true"
              row-back-ground-color="#ffffff"
              font-weight="lighter"
              :render="isViewingSubjective == 0"
            >
            </DetailedAnalysisRow>
            <DetailedAnalysisRow
              v-for="(row, index) in oneWordQuestions"
              :key="index"
              :col0="index + 1"
              :col1="row.question"
              :col2="row.correct"
              :col3="row.difficulty"
              :col4="row.response != '-1' ? row.response : ''"
              :col5="row.marks"
              :render-answer="true"
              :col6="row.explanation"
              row-back-ground-color="#ffffff"
              font-weight="lighter"
              :render="isViewingSubjective == 1"
              :is-admin="false"
              type="one"
              :id="row.id"
              :total="row.total"
            ></DetailedAnalysisRow>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import DemoNavbar from "@/components/DemoNavbar";
import GeneralPerformanceTable from "@/components/AnalysisComponents/GeneralPerformanceTable";
import ResultTable from "@/components/AnalysisComponents/ResultTable";
import DetailedAnalysisRow from "@/components/AnalysisComponents/DetailedAnalysisRow";

export default {
  name: "DemoResponses",
  components: {
    DetailedAnalysisRow,
    ResultTable,
    GeneralPerformanceTable,
    DemoNavbar
  },
  mounted() {
    this.test_id = this.$route.query.test_id;

    this.fetchQuestion();
    store.state.loading = false;
  },
  data() {
    return {
      test_id: "",
      page_id: "",
      total: 0,
      title: "",
      startTime: 0,
      avg: 0,
      percentile: 0,
      correctAnswer: [],
      sectionArray: [],
      responses: [],
      remark: "",
      rating: "",
      studentName: "",
      subjectiveQuestion: [],
      rank: 0,
      user_test_id: "",
      isViewingBrief: 0,
      questions: [],
      answered: 0,
      unanswered: 0,
      score: 0,
      marksOfEachResponse: [],
      isViewingSubjective: 0,
      tableData: [],
      oneWordQuestions: []
    };
  },
  methods: {
    async fetchQuestion() {
      // try {
      this.questions = JSON.parse(this.$route.params.questions);
      this.responses = this.$route.params.response;
      this.sectionArray = JSON.parse(this.$route.params.sections);
      this.total = this.questions.length;

      let questionId = {};
      this.questions.forEach((data, index) => {
        questionId[data.id] = index;
      });
      this.questions.forEach(data => {
        if (data.correct == this.responses[Number(data.id)]) {
          this.score += Number(data.marks);
          this.marksOfEachResponse.push(data.marks);
        } else this.marksOfEachResponse.push(0);
      });

      this.responses.forEach(data => {
        if (data !== "-1" && data != "") {
          this.answered++;
        }
      });

      this.unanswered = this.total - this.answered;
      let sectionId = {};
      this.sectionArray.forEach((data, index) => {
        data["sNo"] = index + 1;
        data["totalQuestions"] = 0;
        data["attempted"] = 0;
        data["totalMarks"] = 0;
        data["marks"] = 0;
        data["notEvaluated"] = 0;
        sectionId[data.section_name] = index;
      });

      this.questions.forEach(data => {
        let section = data.section;

        this.sectionArray[sectionId[section]]["totalMarks"] += data.marks;

        let marks = this.marksOfEachResponse[questionId[data.id]];

        console.log(this.marksOfEachResponse[questionId[data.id]], data.id);

        let response = this.responses[data.id];

        this.sectionArray[sectionId[section]]["totalQuestions"]++;

        if (data.marks >= marks) {
          this.sectionArray[sectionId[section]]["marks"] += Number(marks);
        }

        console.log(sectionId, response);

        if (response !== "-1" && response != null)
          this.sectionArray[sectionId[section]]["attempted"]++;
      });

      this.sectionArray.forEach((data, index) => {
        data["percentage"] = parseFloat(
          ((data.marks / data.totalMarks) * 100).toFixed(2)
        );
      });

      await this.fillDetailedAnalysisData();
      // } catch (e) {
      //   console.log(e);
      //   await this.$router.push({
      //     name: "Login"
      //   });
      // }
    },
    async fillDetailedAnalysisData() {
      let questionId = {};
      this.questions.forEach((data, index) => {
        console.log(data.id, index);
        questionId[data.id] = index;
      });

      const getQuestion = id => {
        this.questions.filter(data => {
          return data.id == id;
        });
      };

      console.log(questionId);
      this.responses.forEach((response, index) => {
        if (!questionId[index]) return;
        if (this.questions[questionId[index]].subType == "0") {
          let oneWord = {
            response: response,
            marks: this.marksOfEachResponse[questionId[index]],
            id: this.questions[questionId[index]].id,
            total: this.questions[questionId[index]].marks
          };
          this.oneWordQuestions.push({
            ...this.questions[questionId[index]],
            ...oneWord
          });
        } else {
          console.log(index);
          let singleRowData = {
            question: this.questions[questionId[index]]["question"],
            answer: this.questions[questionId[index]]["correct"],
            userAnswer:
              this.responses[index] === "-1"
                ? "Not Attempted"
                : `${this.responses[index]}`,
            explanation: this.questions[questionId[index]]["explanation"],
            marks: this.marksOfEachResponse[questionId[index]],
            difficulty: this.questions[questionId[index]]["difficulty"]
          };

          this.tableData.push(singleRowData);
        }
      });
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300&display=swap");

table,
th,
td {
  border: 1px solid black;
}

.table-title {
  color: red;
  align-items: center;
  text-align: center;
  font-family: "Mukta", sans-serif;
}

.table-container {
  margin: 0 50px 50px 50px;
}

table {
  width: 100%;
  overflow-x: hidden;
  border-collapse: collapse;
}

.table-body {
  overflow: auto;
}

.active-table {
  color: white;
  background-color: #337ab7 !important;
}

.question-type-nav {
  padding: 10px;
  width: 90%;
  margin: 0 auto;
}

.mcq {
  width: 50%;
  background-color: white;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-radius: 0;
}

.subjective-question {
  width: 50%;
  background-color: white;
  border-radius: 0;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

@media all and (max-width: 700px) {
  .table-container {
    width: 85vw;

    overflow-x: auto;
    white-space: nowrap;
  }
}

@media all and (max-width: 700px) {
  .question-type-nav {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .table-container {
    margin-left: 10px;
  }

  .btn {
    /*word-wrap: break-word;*/
    white-space: normal;
    height: 100px;
  }
}

.data {
  margin-top: 20px;
}
</style>
