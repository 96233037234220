<template>
  <tr class="detailed-row" :style="style" v-if="render">
    <th class="col0" :style="style">{{ col0 }}</th>
    <th class="col1" :style="style">{{ col1 }}</th>
    <th class="col2" v-if="renderAnswer" :style="style">{{ col2 }}</th>
    <th class="col2" :style="style">{{ col3 }}</th>
    <th class="col2" :style="style">
      {{ col4 }}
    </th>
    <th class="col3" :style="style">
      <div style="display: flex;position: relative;">
        <p>{{ col5 }}</p>
        <div @click="updateMarks" v-if="isAdmin">
          <span
            class="iconify"
            data-icon="ant-design:edit-outlined"
            data-inline="false"
          ></span>
        </div>
      </div>
    </th>
    <th class="col4" :style="style">{{ col6 }}</th>
  </tr>
</template>

<script>
export default {
  name: "DetailedAnalysisRow",
  emits: ["marksUpdated"],
  props: [
    "col0",
    "col1",
    "col2",
    "col3",
    "col4",
    "col5",
    "col6",
    "rowBackGroundColor",
    "fontWeight",
    "render",
    "renderAnswer",
    "isAdmin",
    "id",
    "type",
    "total"
  ],
  computed: {
    style() {
      return {
        backgroundColor: this.rowBackGroundColor,
        fontWeight: this.fontWeight
      };
    }
  },
  methods: {
    updateMarks() {
      swal("Enter New Marks:", {
        content: "input"
      }).then(value => {
        if (value > this.total) {
          swal(
            "Please Enter marks less than total marks,which is " + this.total
          );
        } else if (this.isNumeric(value)) {
          this.$emit("marksUpdated", {
            id: this.id,
            marks: value,
            type: this.type
          });
        } else {
          swal("Please enter a valid number");
        }
      });
    },
    isNumeric(str) {
      if (typeof str != "string") return false;
      return !isNaN(str) && !isNaN(parseFloat(str));
    }
  }
};
</script>

<style scoped>
th {
  border: solid 1px;
}

.col0 {
  width: 10%;
}

.col1 {
  width: 40%;
}

.col2 {
  width: 10%;
}

.col3 {
  width: 10%;
}

.col4 {
  width: 30%;
}

.iconify {
  right: 0;
  position: absolute;
}

@media all and (max-width: 700px) {
  .col1 {
    width: 20%;
  }
}
</style>
